import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { onAuthStateChanged } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../AuthContext';

import CurrencyTextField from "../../../components/CurrencyTextField";

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import {
  BANDEIRAS,
  REGIOES,
  FIREBASE_CONFIG,
  formatBandeira,
  formatCurrency,
  hasFullPermission
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Box from "@mui/material/Box";

const vet_bandeiras = BANDEIRAS;

const Store = ({ firebaseApp }) => {
  const theme = useTheme();

  const { user, name } = useAuth()

  const navigate = useNavigate();

  const [newDialog, setNewDialog] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [rows, setRows] = useState([]);

  const [filter, setFilter] = useState("");

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/store`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
          setRows(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const handleSave = async () => {
    if (newDialog) {
      
    } else {
      setLoading(true);

      let formData = {
        ...currentRow,
        last_user_update: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .put(`${apiUrl}/api/v1/store/${currentRow.id}`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            toast.success('Loja atualizada com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleChange = (event, _id) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, [_id]: value }));
  };

  const handleEditClick = (row) => {
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
  };

  const handleInputNumChange = (values) => {
    const { value } = values;
    setCurrentRow((prevRow) => ({ ...prevRow, 'gmv': value }));
  };

  const columns = [
    {
      field: "id",
      headerName: "Sigla",
      editable: false,
    },
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 120,
      editable: false,
      renderCell: (params) => formatBandeira(params.value),
    },
    {
      field: "name",
      headerName: "Loja",
      flex: 1,
      editable: false,
    },
    {
      field: "avg_gmv",
      headerName: "Média GMV últimos 12 meses",
      flex: 1,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         aria-label="edit"
    //         color="primary"
    //         onClick={() => handleEditClick(params.row)}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //       {/* <IconButton aria-label="delete" color="primary" disabled>
    //         <DeleteIcon />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];


  const callFilter = () => {

    // Filtro inline aplicado ao array de rows
    const filteredRows = searchResults.filter(
      (row) =>
      (filter === "" ||
        row.name.toLowerCase().includes(filter.toLowerCase()) ||
        row.id.toLowerCase().includes(filter.toLowerCase()) ||
        row.retailer.toLowerCase().includes(filter.toLowerCase()))
    );

    setRows(filteredRows.sort((a, b) => {
      return (b.name === a.name) ? 0 : b.name ? 1 : -1;
    }))
  }

  useEffect(() => {
    callFilter()
  }, [filter]);

  return (
    <React.Fragment>
      <h2>Lojas</h2>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <TextField
              margin="dense"
              name="id"
              label="Busca"
              type="text"
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
        </div>

      </div>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 50]}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
        {/* 
        loading fullscreen
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>

      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>{newDialog ? "Nova" : "Editar"} Loja</DialogTitle>
        <DialogContent>
          {currentRow && (
            <>
              <Grid
                container
                direction={'column'}
                style={{ marginBottom: "0px" }}
              >
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.id}
                  >
                    <TextField
                      margin="dense"
                      name="id"
                      label="Sigla"
                      type="text"
                      fullWidth
                      disabled
                      value={currentRow.id}
                      error={!!errors.id}
                      // {...register("id", {
                      //   required: "Campo Obrigatório",
                      // })}
                      onChange={(e) => handleChange(e, "id")}
                    />
                    {errors.id && (
                      <FormHelperText>{errors.id.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.name}
                  >
                    <TextField
                      margin="dense"
                      name="name"
                      label="Loja"
                      type="text"
                      fullWidth
                      disabled
                      value={currentRow.name}
                      error={!!errors.name}
                      // {...register("name", {
                      //   required: "Campo Obrigatório",
                      // })}
                      onChange={(e) => handleChange(e, "name")}
                    />
                    {errors.name && (
                      <FormHelperText>{errors.name.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "9px",
                      marginBottom: "5px",
                      minWidth: 300,
                    }}
                    error={!!errors.retailer}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Bandeira *
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // {...register("retailer", {
                      //   required: "*",
                      // })}
                      disabled
                      value={currentRow.retailer}
                      defaultValue={""}
                      input={<OutlinedInput label="Bandeira *" />}
                    >
                      {vet_bandeiras.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.retailer && (
                      <FormHelperText>{errors.retailer.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      minWidth: 120,
                    }}
                    error={!!errors.gmv}
                  >
                    <CurrencyTextField
                      name="gmv"
                      label={'GMV'}
                      fullWidth
                      value={currentRow.gmv}
                      error={!!errors.gmv}
                      onChange={(e) => handleInputNumChange(e)}
                    />

                    {errors.gmv && (
                      <FormHelperText>
                        {errors.gmv.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          {hasFullPermission(user) && <Button onClick={handleSubmit(handleSave)} color="primary">
            Salvar
          </Button>}
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

export default Store;
