import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import {
  Button,
  Box,
  CircularProgress,
} from "@mui/material";

import DoorBackIcon from '@mui/icons-material/DoorBack';

import { BANDEIRAS, REGIOES, formatDate, getEstadosPorRegiao } from "../../../../router/store/types";

import { useAuth } from '../../../../AuthContext';
import ProposalSummary from "../ProposalSummary";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProposalPdf from "../ProposalPdf";

export default function ProposalFormView() {
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    client_id: "",
    client: "",
    clientContact: "",
    campaign: "",
    totalCOGS: 0,
    totalMidia: 0,
    inc_user_id: 0,
    inc_date: "",
    comments: "",
    retailer: ""
  });

  const [edit, setEdit] = useState(false);

  const [retailer, setRetailer] = useState("");
  const [assets, setAssets] = useState([]);
  const [stores, setStores] = useState([]);
  const [storesAssets, setStoresAssets] = useState([]);
  const [clients, setClients] = useState([]);

  const [assetsSelected, setAssetsSelected] = useState([]);
  const [storesSelected, setStoresSelected] = useState([]);
  const [storesAssetsSelected, setStoresAssetsSelected] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { param_proposal_id } = useParams();

  const {user, name} = useAuth()

  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const getDocumentById = async () => {
    try {
      axios
        .get(`${apiUrl}/api/v1/proposal/${param_proposal_id}`, {})
        .then(async (response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          const proposal = response.data.proposal;
          const assets = response.data.assets;

          // Carregando dados da proposta
          let _values = {
            proposal_id: param_proposal_id,
            client_id: proposal.client_id,
            client: proposal.client,
            clientContact: proposal.client_contact,
            campaign: proposal.campaign,
            totalCOGS: proposal.total_cogs,
            totalMidia: proposal.total_midia,
            inc_user_id: proposal.inc_user_id,
            inc_date: proposal.inc_date,
            comments: proposal.comments,
            retailer: proposal.retailer
          }
          setValues(_values);

          let _storesAssetsSelected = assets.map((el) => ({
            "id": el.id,
            "asset_id": el.asset_id,
            "retailer": proposal.retailer,
            "sigla": el.store_id,
            "loja": el.store,
            "asset": el.asset,
            "qty_proposta": el.qty,
            "price_per_asset": el.midia
          }))
          setStoresAssetsSelected(_storesAssetsSelected)

          setRetailer(proposal.retailer)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (param_proposal_id) {
        setEdit(true)
        await getDocumentById();
        setActiveStep(4)
      }
    };
    fetch();
  }, []);

  const redirectToStart = () => {
    navigate("/proposals");
  };

  return (
    <>
      <h2>Visualização da Proposta ID <i>{param_proposal_id}</i></h2>

      <Box sx={{ width: "100%"}}>
        <Box sx={{ padding: 2 }}>
          
          <ProposalSummary data={{ proposal: values, assets: storesAssetsSelected }} edit={edit} />
          
          <Box
            sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}
          >
            <Button sx={{ marginRight: 1 }} onClick={redirectToStart} color="error" variant="contained">
              Sair <DoorBackIcon sx={{ marginLeft: "5px" }} />
            </Button>

            <div>
              <ProposalPdf data={{ proposal: values, assets: storesAssetsSelected }} edit={edit} />
            </div>
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}

      </Box>
    </>
  );
}
