import React, { useState, useEffect } from "react";

import axios from "axios";

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import toast, { Toaster } from "react-hot-toast";

import {
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  CircularProgress
} from "@mui/material";

import PreviewIcon from '@mui/icons-material/Preview';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

import { DataGrid } from "@mui/x-data-grid";

import { formatCurrency, formatDecimal, formatMilions, formatDate } from "../../../../router/store/types";
import e from "cors";

function ProposalSummary({ data, callback, edit }) {

  const [pricing, setPricing] = useState([]);

  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchPricing = async () => {
    console.log("Loading...");

    setLoading(true);

    const year_month = getYearMonthWithMostDays(data.proposal.start_date, data.proposal.end_date);
    const stores = data.stores.map(el => el.id).join(',');
    
    let formData = {
      retailer: data.proposal.retailer,
      stores: stores,
      year_month: year_month,
      assets: data.assets
    }

    try {
      const response = await axios.post(`${apiUrl}/api/v1/store-asset/pricing`, formData);

      const data = response.data;

      if (apiUrl.includes("/localhost")) console.log("data", data);

      await calcPricing(data)

      setLoading(false);

    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  function sumField(dataArray, fieldName) {
    return dataArray.reduce((sum, item) => {
      // Verifica se o campo existe e é um número, caso contrário, retorna 0
      return sum + (typeof item[fieldName] === 'number' ? item[fieldName] : 0);
    }, 0);
  }

  const calcPricing = async (parameters) => {

    let proposal_assets = data.stores.flatMap(store =>
      data.assets.map(asset => {
        const matchedParam = parameters.find(el => el.sigla === store.id && el.asset_id === asset.id);
        
        console.log(store.id, asset.id, parameters, matchedParam)

        // if (!matchedParam) {
        //   return {
        //     sigla: store.id,
        //     loja: `⚠️ Sem regras`,
        //     asset_id: asset.id,
        //     asset: asset.title,
        //   };
        // };

        return  {
          id: store.id,                   
          name: store.name,               
          asset_id: asset.id,             
          asset: asset.title, 
          start_date: asset.start_date,
          end_date: asset.end_date,
          ...matchedParam,                
          qty_proposta: asset.qty_proposta,
          campaign_cost: parseFloat(matchedParam.max_cogs_unit - (((matchedParam.max_cogs_unit - matchedParam.min_cogs_unit) / (matchedParam.qty_max - matchedParam.qty_min)) * (asset.qty_proposta - matchedParam.qty_min))),
          total: asset.qty_proposta * matchedParam.price_per_asset
        };
      }).filter(item => item !== null)
    );

    console.log('proposal_assets', proposal_assets)

    proposal_assets = proposal_assets.map(el => ({
      ...el,
      campaign_margin: ((el.price_per_asset - el.campaign_cost) / el.price_per_asset) * 100,
    }));

    data.proposal.totalMidia = sumField(proposal_assets, 'total')
    data.proposal.totalCOGS = sumField(proposal_assets, 'campaign_cost')

    const rowsWithId = proposal_assets.map((row, index) => ({
      lineId: index + 1,
      ...row,
    }));

    setPricing(rowsWithId)

    data.pricing = rowsWithId

    callback(data)
  }
  
  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchPricing();
    };

    fetch();
  }, []);

  function getYearMonthWithMostDays(startDate, endDate) {
    // Converta as datas para objetos Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    let maxDays = 0;
    let maxYearMonth = '';

    // Função auxiliar para limitar as datas ao mês
    const limitDateToMonth = (date, isStart) => {
      const year = date.getFullYear();
      const month = date.getMonth();
      return isStart ? new Date(year, month, 1) : new Date(year, month + 1, 0);
    };

    // Função para contar os dias úteis entre as datas, limitando ao mês
    const countDaysInMonth = (start, end) => {
      const startOfMonth = limitDateToMonth(start, true);
      const endOfMonth = limitDateToMonth(start, false);
      const actualStart = start > startOfMonth ? start : startOfMonth;
      const actualEnd = end < endOfMonth ? end : endOfMonth;
      return (actualEnd - actualStart) / (1000 * 60 * 60 * 24) + 1;
    };

    let current = new Date(start.getFullYear(), start.getMonth(), 1);

    // Percorre mês a mês entre startDate e endDate
    while (current <= end) {
      const daysInMonth = countDaysInMonth(current, end);
      const yearMonth = `${current.getFullYear()}${String(current.getMonth() + 1).padStart(2, '0')}`;

      if (daysInMonth > maxDays) {
        maxDays = daysInMonth;
        maxYearMonth = yearMonth;
      }

      // Ir para o próximo mês
      current.setMonth(current.getMonth() + 1);
    }

    // Subtrai 12 meses da data do yearMonth com mais dias
    const year = parseInt(maxYearMonth.slice(0, 4));
    const month = parseInt(maxYearMonth.slice(4, 6));

    const dateWithMostDays = new Date(year, month - 1);
    dateWithMostDays.setMonth(dateWithMostDays.getMonth() - 12);

    // Retorna o valor no formato yyyyMM
    const resultYearMonth = `${dateWithMostDays.getFullYear()}${String(dateWithMostDays.getMonth() + 1).padStart(2, '0')}`;

    return resultYearMonth;
  }

  const columns = [
    {
      field: "loja",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.sigla} - {params.row.loja}</>
      )
    },
    {
      field: "store_avg_gmv",
      headerName: "GMV Médio 12 meses",
      width: 80,
      valueFormatter: (params) => formatMilions(params.value),
      headerClassName: 'multi-line-header' 
    },
    {
      field: "asset",
      headerName: "Ativo",
      flex: 1
    },
    {
      field: "group",
      headerName: "Grupo",
      flex: 1,
      width: 120
    },
    {
      field: "periodo",
      headerName: "Período",
      width: 180,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 'bold', width: '80px' }}>
          {dayjs(params.row.start_date).format('DD/MM/YYYY')} a {dayjs(params.row.end_date).format('DD/MM/YYYY')}
        </div>
      ),
      headerClassName: 'multi-line-header' 
    },
    {
      field: "price_per_asset",
      headerName: "Preço por Ativo",
      width: 100,
      valueFormatter: (params) => formatCurrency(params.value),
      headerClassName: 'multi-line-header' 
    },
    {
      field: "qty_proposta",
      headerName: "Qtde. Proposta",
      width: 80,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 'bold', width: '80px' }}>{params.row.qty_proposta}</div>
      ),
      headerClassName: 'multi-line-header' 
    },
    {
      field: "campaign_cost",
      headerName: "Custo Campanha",
      width: 80,
      valueFormatter: (params) => formatCurrency(params.value),
      headerClassName: 'multi-line-header'
    },
    {
      field: "campaign_margin",
      headerName: "Margem",
      width: 70,
      valueFormatter: (params) => formatDecimal(params.value) + "%",
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 120,
      renderCell: (params) => (
        <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(params.value)}</div>
      ),
    },
  ];

  return (
    <>
      {!edit &&
        <Typography sx={{ fontWeight: "bold", fontSize: "16px", display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "10px" }}>
          <ChecklistRtlIcon color="primary" style={{ fontSize: 50, marginRight: 10 }} /><div>Verifique os dados da proposta</div>
        </Typography>
      }

      <Box sx={{ padding: 1, height: (!edit ? "calc(100vh - 370px)": "calc(100vh - 250px)") , overflowY: "scroll" }}>
        
        <div style={{display: 'flex', justifyContent: 'space-between'}}>

          <fieldset className="summary-field" style={{ width: '100%' }}>
            <legend>Cliente</legend>
            <div>{data.proposal.client}</div>
          </fieldset>

          {data.proposal.inc_date &&
            <fieldset className="summary-field" style={{ width: '180px' }}>
              <legend>Data da Proposta</legend>
              <div>{formatDate(data.proposal.inc_date)}</div>
            </fieldset>
          }

        </div>

        <fieldset className="summary-field">
          <legend>Contato</legend>
          <div>{data.proposal.clientContact}</div>
        </fieldset>

        <fieldset className="summary-field">
          <legend>Campanha</legend>
          <div>{data.proposal.campaign}</div>
        </fieldset>

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Início</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatDate(data.proposal.start_date)}</div>
          </fieldset>

          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Fim</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatDate(data.proposal.end_date)}</div>
          </fieldset>
        </div>

        {data.proposal.comments && <fieldset className="summary-field">
            <legend>Observações</legend>
            <div>{data.proposal.comments}</div>
          </fieldset>
        }

        {/* <div style={{display: "flex", justifyContent: "space-between"}}>
          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Mídia</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(data.proposal.totalMidia)}</div>
          </fieldset>

          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Custos Operação</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(data.proposal.totalCOGS)}</div>
          </fieldset>
        </div> */}
        
        <Box sx={{ marginTop: "15px" }}>
          <DataGrid
            rows={pricing}
            columns={columns}
            loading={loading}
            autoHeight
            disableRowSelectionOnClick
            hideFooter
            localeText={{
              noRowsLabel: "Nada encontrado",
            }}
            pinnedColumns={{ left: ['loja'] }} 
            getRowId={(row) => row.lineId}     
            sx={{
              marginRight: "20px",
              ".MuiDataGrid-overlayWrap": {
                height: "55px !important",
                fontWeight: "bold",
              },
              ".MuiDataGrid-overlayWrapperInner": {
                height: "55px !important",
                fontWeight: "bold",
              },
              ".MuiDataGrid-overlayWrapper": {
                height: "55px !important",
                fontWeight: "bold",
              },
              fontSize: "10px", // Tamanho da fonte reduzido
              "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                fontSize: "12px", // Tamanho da fonte reduzido para cabeçalho e células
                padding: "5px", // Espaçamento interno reduzido
              },
            }}
            />
        </Box>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>

          <fieldset className="summary-field" style={{ width: "200px", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Custo Total</legend>
            <div style={{ width: "100%", textAlign: 'right', paddingRight: "10px", fontSize: '20px', fontWeight: 'bold', color: 'red' }}>⛏️</div>
          </fieldset>

          <fieldset className="summary-field" style={{ width: "200px", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Proposta</legend>
            <div style={{ width: "100%", textAlign: 'right', paddingRight: "10px", fontSize: '20px', fontWeight: 'bold', color: 'red' }}>{!data.proposal.totalMidia ? '0,00' : formatCurrency(parseFloat(data.proposal.totalMidia))}</div>
          </fieldset>
        </div>

        
        {/* loading fullscreen */}
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}

      </Box>
    </>
  );
}

export default ProposalSummary;
