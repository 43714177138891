import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { onAuthStateChanged } from "firebase/auth";
import FindInPageIcon from '@mui/icons-material/FindInPage';

import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";

import {
  BANDEIRAS,
  REGIOES,
  FIREBASE_CONFIG,
  getColor,
  formatDate,
  formatBandeira,
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

import Box from "@mui/material/Box";

const Proposals = ({ firebaseApp }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/proposal`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const handleEditClick = (row) => {
    setCurrentRow({ ...row });
    setLoading(true)
    navigate(`/proposals/${row.id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  /*
      {
      field: "campaign",
      headerName: "Campanha",
      flex: 1,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
  */

  const columns = [
    {
      field: "id",
      headerName: "ID Proposta",
      width: 200
    },
    {
      field: "name",
      headerName: "Cliente",
      flex: 1,
      editable: false,
    },
    {
      field: "campaign",
      headerName: "Campanha",
      flex: 1,
      editable: false,
    },
    {
      field: "total_cogs",
      headerName: "Custo Campanha",
      flex: 1,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "total_midia",
      headerName: "Mídia",
      flex: 1,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "inc_date",
      headerName: "Data Proposta",
      flex: 1,
      editable: false,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 100,
      editable: false,
      renderCell: (params) => formatBandeira(params.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditClick(params.row)}
          >
            <FindInPageIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <h2>Propostas</h2>

      <div
        style={{
          marginLeft: "0px",
          display: "flex",
          justifyContent: "right",
          gap: "40px",
          width: "100%",
          marginBottom: "25px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "200px" }}
          startIcon={<AddIcon />}
          onClick={() => {
            navigate("/proposals/new");
          }}
        >
          Novo
        </Button>
      </div>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={searchResults}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 50]}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
        {/* 
        loading fullscreen
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>
    </React.Fragment>
  );
};

export default Proposals;
