import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

import { useForm } from "react-hook-form";

import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuth } from '../../../../AuthContext';

import CurrencyTextField from "../../../../components/CurrencyTextField";

import {
  Button,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from "@mui/material";

import {
  ESTADOS,
  hasFullPermission
} from "../../../../router/store/types";

import { useNavigate, useParams } from "react-router-dom";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NewAssetCosts = ({ firebaseApp }) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { id } = useParams();

  const [newDialog, setNewDialog] = useState(false);

  const { user, name } = useAuth()

  const [data, setData] = useState({
    type: ""
  });

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [typeList, setTypeList] = useState([]);

  const [values, setValues] = useState({});

  const { param_type_id } = useParams();

  const handleChange = (uf, field, values) => {
    const { value } = values;
    setValues((prevValues) => ({
      ...prevValues,
      [uf]: {
        ...prevValues[uf],
        [field]: value,
      },
    }));
  };

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchTypes = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-type`)
        .then((response) => {
          // if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setTypeList(response.data.map(el => ({ id: el.id, group: el.group })));
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setTypeList([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const getDocumentById = async () => {

    console.log('fetching getDocumentById...')

    try {
      setData((prevData) => ({
        ...prevData,
        "type": param_type_id,
      }));

      axios
        .get(`${apiUrl}/api/v1/asset-cost/${param_type_id}`, {})
        .then(async (response) => {
           if (apiUrl.includes("/localhost")) console.log("data", response.data);

          const data = response.data; 

          const UFs = data.reduce((acc, el) => {
            acc[el.state] = {
              max_total_cogs: el.max_total_cogs,
              min_total_cogs: el.min_total_cogs
            };
            return acc;
          }, {});

          console.log('UFs', UFs)

          setValues(UFs);
        
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...", param_type_id);

    const fetch = async () => {
      await fetchTypes();

      if (param_type_id !== undefined)
        await getDocumentById()
      else
        setNewDialog(true)
    };

    fetch();
  }, []);

  const onSubmit = async (xdata) => {

    if (!data.type) {
      toast.error("O campo Tipo é obrigatório!")
      return;
    }

    setIsSaving(true);

    let formData = {
      id: data.type,
      costs: values,
      last_user_update: `${name} (${user})`,
    }

    try {
      const response = await axios.post(`${apiUrl}/api/v1/asset-cost`, formData);

      if (response.status !== 201) {
        throw new Error(`Erro: Código de status ${response.status}`);
      }

      const result = response.data.id

      toast.success("Custos atualizados com sucesso!");

      setTimeout(() => {
        navigate("/asset-costs");
      }, 1000); 

    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }

    setIsSaving(false);
  };

  return (
    <React.Fragment>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {newDialog ? (
          <>
            <h2>Novos Custos de Ativo</h2>
          </>
        ) : (
          <>
            <h2>
              Edição de Custos de Ativo
            </h2>
          </>
        )}

        <FormControl
          sx={{ marginLeft: "0px", marginBottom: "25px", minWidth: 300 }}
        >
          <InputLabel id="demo-multiple-name-label">Grupo *</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            required
            disabled={!newDialog}
            value={data.type}
            name="type"
            onChange={handleChangeSelect}
            input={<OutlinedInput label="Grupo" />}
            error={!data.type}
          >
            {typeList.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.group}
              </MenuItem>
            ))}
          </Select>
          {!data.type && (
            <FormHelperText error={!data.type}>Grupo é obrigatório</FormHelperText>
          )}
        </FormControl>

        {ESTADOS.map((uf) => (
          <Grid container spacing={2} key={uf} sx={{ width: '700px' }}>

            <Grid item xs={2} sx={{ marginTop: '20px' }}>
              <TextField
                label={`UF`}
                value={uf}
                disabled
                fullWidth
                size="small"           // Campo menor
                variant="outlined"      // Variante compacta
                inputProps={{ style: { fontSize: 12 } }} // Texto menor
                InputLabelProps={{ style: { fontSize: 12 } }} // Label menor
              />
            </Grid>

            <Grid item xs={4} sx={{ marginTop: '20px' }}>
              <CurrencyTextField
                label={`COGS Total Mínimo (${uf})`}
                value={values[uf]?.min_total_cogs || "0"}
                onChange={(e) => handleChange(uf, "min_total_cogs", e)}
                fullWidth
                size="small"           // Campo menor
                variant="outlined"      // Variante compacta
                inputProps={{ style: { fontSize: 12 } }} // Texto menor
                InputLabelProps={{ style: { fontSize: 12 } }} // Label menor
              />
            </Grid>

            <Grid item xs={4} sx={{marginTop: '20px'}}>
              <CurrencyTextField
                label={`COGS Total Máximo (${uf})`}
                value={values[uf]?.max_total_cogs || "0"}
                onChange={(e) => handleChange(uf, "max_total_cogs", e)}
                fullWidth
                size="small"           // Campo menor
                variant="outlined"      // Variante compacta
                inputProps={{ style: { fontSize: 12 } }} // Texto menor
                InputLabelProps={{ style: { fontSize: 12 } }} // Label menor
              />
            </Grid>
          </Grid>
        ))}

        <>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "0px",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              width: "280px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="large"
              loading={true}
              disabled={isSaving}
              startIcon={
                isSaving ? <CircularProgress size={20} /> : <SaveIcon />
              }
            >
              {isSaving ? "Salvando..." : "Salvar"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/asset-costs");
              }}
            >
              Voltar
            </Button>
          </div>
        </>

      </form>
    </React.Fragment>
  );
};

export default NewAssetCosts;
