import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { onAuthStateChanged } from "firebase/auth";

import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl
} from "@mui/material";

import {
  BANDEIRAS,
  REGIOES,
  FIREBASE_CONFIG,
  formatBandeira,
  formatCurrency,
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Box from "@mui/material/Box";

const AssetsStore = ({ firebaseApp }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);

  const [sigla, setSigla] = useState("");
  const [retailer, setRetailer] = useState("Atacadão");

  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async (retailer = 'Atacadão') => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/store-asset-all`, { params: { retailer: retailer }})
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const handleChangeSelect = async (e) => {
    const { name, value } = e.target;
    setRetailer(value);

    await fetchAll(value);
  };

  const columns = [
    {
      field: "sigla",
      headerName: "Sigla",
      editable: false,
    },
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 120,
      editable: false,
      renderCell: (params) => formatBandeira(params.value),
    },
    {
      field: "loja",
      headerName: "Loja",
      flex: 1,
      editable: false,
    },
    {
      field: "asset",
      headerName: "Ativo",
      flex: 1,
      editable: false,
    },
    {
      field: "gmv",
      headerName: "GMV",
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "qty",
      headerName: "Disponível",
      flex: 1,
    },
    {
      field: "base_price",
      headerName: "Preço Base",
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "price_per_asset",
      headerName: "Preço por Ativo",
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         aria-label="edit"
    //         color="primary"
    //         onClick={() => handleEditClick(params.row)}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //       {/* <IconButton aria-label="delete" color="primary" disabled>
    //         <DeleteIcon />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  // Filtro inline aplicado ao array de rows
  const filteredRows = searchResults.filter(
    (row) =>
      (sigla === "" ||
        row.sigla.toLowerCase().includes(sigla.toLowerCase()) || row.loja.toLowerCase().includes(sigla.toLowerCase()) || row.asset.toLowerCase().includes(sigla.toLowerCase())) &&
      (retailer === "" || row.retailer === retailer)
  );

  return (
    <React.Fragment>
      <h2>Ativos Por Loja</h2>

      <Grid container alignItems="center" style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          <Box sx={{ marginLeft: "0px", width: "300px" }}>
            <FormControl fullWidth>
              <InputLabel id="lblBandeira">Bandeira</InputLabel>
              <Select
                fullWidth
                value={retailer}
                labelId="lblBandeira"
                id="cmbBandeira"
                name="retailer"
                label="Bandeira"
                onChange={handleChangeSelect}
              >
                {BANDEIRAS.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
                </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TextField
            margin="dense"
            name="id"
            label="Sigla"
            type="text"
            onChange={(e) => setSigla(e.target.value)}
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          disableRowSelectionOnClick
          getRowId={(row) => row.store_asset_id}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
        {/* 
        loading fullscreen
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>
    </React.Fragment>
  );
};

export default AssetsStore;
