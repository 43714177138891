import React, { useState, useEffect } from "react";

import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  FormControl
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { formatDate, formatCurrency, formatBandeiraExt, formatBandeira, REGIOES, getEstadosPorRegiao } from "../../router/store/types";

import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VerifiedIcon from '@mui/icons-material/Verified';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function ProposalStoreGrid({ rows, assets, header, callback, edit }) {

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [loading, setLoading] = useState(true);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const [filter, setFilter] = useState("");
  const [regiao, setRegiao] = useState("Todas");

  const [regionsFiltro, setRegionsFiltro] = useState([]);

  const [stores, setStores] = useState([]);
  const [items, setItems] = useState([]);

  const [issuesStore, setIssuesStore] = useState();
  const [issuesList, setIssuesList] = useState([]);

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setRegiao(value);
  };

  const fetchAvailability = async (store, assets) => {
    console.log("Loading...");

    setLoadingCheck(true);

    let formData = {
      store: store,
      assets: assets
    }

    try {
      const response = await axios.post(`${apiUrl}/api/v1/store-asset/availability`, formData);
      setLoadingCheck(false);
      
      return response.data
      
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const handleCheckAvailability = async (store) => {
    const data = await fetchAvailability(store, assets);
    console.log('handleCheckAvailability', store, data)   
    return data;
  }

  const handleButtonAvailability = async (store) => {
    let response = await handleCheckAvailability(store);
    console.log('handleButtonAvailability', response)

    const result = response.assets.every(item => item.status === 1) ? 1 : 2;

    let _items = items.map((item) =>
      item.id === store ? { ...item, status: result, assets: response.assets } : item
    )

    setItems(_items.sort((a, b) => {
      return (b.checked === a.checked) ? 0 : b.checked ? 1 : -1;
    }));

    const _checked = _items.find(el => el.id === store).checked;
    
    getSelected(store, _checked, _items)
  }

  const fetchAllStores = async () => {
    console.log("Loading...");

    setLoading(true);

    const assets_list = assets.map(el => el.id)

    try {
      axios
        .get(`${apiUrl}/api/v1/store`, {
          params: {
            retailer: header.retailer,
            assets: assets_list
          }
        })
        .then((response) => {
          // if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setStores(response.data);
          callFilter(response.data)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setStores([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchAllStores();
    };
    setLoading(true)
    fetch();
    setLoading(false)
  }, []);

  const callFilter = (_stores = []) => {

    let data = stores.length === 0 ? _stores : stores

    data = data.map(store => {
      const { status, checked } = rows.find(s => s.id === store.id) || {};
      return { ...store, status, checked };
    });

    // Filtro inline aplicado ao array de rows
    const filteredRows = data.filter(
      (row) =>
        (filter === "" ||
          row.id.toLowerCase().includes(filter.toLowerCase()) ||
          row.name.toLowerCase().includes(filter.toLowerCase())) &&
        (regiao === "Todas" ||
          row.region.toUpperCase() === regiao ||
          row.state.toUpperCase() === regiao)
    );

    setItems(filteredRows.sort((a, b) => {
      return (b.checked === a.checked) ? 0 : b.checked ? 1 : -1;
    }))

    const uniqueRegions = [...new Set(data.map(item => item.region))];
    let regions_filtro = REGIOES.filter((el) => uniqueRegions.includes(el.id))
    setRegionsFiltro(regions_filtro)
  }

  const handleValueChange = (e, params) => {
    let newValue = Number(e.target.value);

    if (newValue % params.row.multiplier > 0) {
      newValue -= (newValue % params.row.multiplier)
      e.target.value = newValue
    }

    let value = Math.floor(e.target.value) > Math.floor(params.row.qty) ? params.row.qty : e.target.value;
    params.api.setEditCellValue({ id: params.id, field: 'qty_proposta', value: Math.floor(value) });

    let _rows = items.map((item) =>
      item.id === params.row.id ? { ...item, qty_proposta: newValue } : item
    )

    setItems(_rows);

    callback(_rows)
  };

  const handleIssues = async (row) => {
    
    const _assets = rows.find(el => el.id === row.id).assets;
    
    console.log(_assets)

    setIssuesStore(`${row.id} - ${row.name}`)
    setIssuesList(_assets)

    setNewDialog(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewDialog(false);
  };

  useEffect(() => {
    callFilter()
  }, [filter, regiao]);

  const columnsPopup = [
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <>
          <ReportProblemIcon sx={{ color: 'red', marginLeft: '10px' }} />
        </>
      ),
    },
    {
      field: "title",
      headerName: "Ativo",
      flex: 1
    },
    {
      field: 'start_date',
      headerName: 'Início',
      width: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: 'end_date',
      headerName: 'Fim',
      width: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 180
    },
    {
      field: "type_desc",
      headerName: "Tipo",
      width: 100
    },
    {
      field: "qty",
      headerName: "Qtd. Disponível",
      width: 150
    },
    {
      field: "qty_proposta",
      headerName: "Qtde.",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px' }}>✏️ {(params.row.qty_proposta) ? params.row.qty_proposta : 0}</div>
      ),
      renderEditCell: (params) => (
        <TextField
          type="number"
          inputProps={{
            step: params.row.multiplier,  // Força valores inteiros
            min: 0,
            max: params.row.qty,
            pattern: "[0-9]*"  // Impede a entrada de não-números
          }}
          value={params.value}
          onKeyDown={(event) => event.preventDefault()}
          onChange={(event) => handleValueChange(event, params)}
        />
      ),
    }
  ];

  function CustomCheckbox({ row }) {
    const [checked, setChecked] = React.useState(row.checked || false);

    const handleCheckboxChange = async (event) => {
      setChecked(event.target.checked);
      await handleSelectedRow(row.id, event.target.checked);
    };

    const handleSelectedRow = async (store, isChecked) => {
      let result = 0;
      let _assets = []
      if (isChecked) {
        const response = await handleCheckAvailability(store);
        result = response.assets.every(item => item.status === 1) ? 1 : 2;
        _assets = response.assets;
      }

      let _items = items.map((item) =>
        item.id === store ? { ...item, status: result, checked: isChecked, assets: _assets } : item
      )

      setItems(_items.sort((a, b) => {
        return (b.checked === a.checked) ? 0 : b.checked ? 1 : -1;
      }));

      getSelected(store, isChecked, _items);
    };

    return (
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        color="primary"
      />
    );
  }

  const getSelected = (store, checked, _items) => {
    rows = rows.filter(item => item.id !== store)

    if (checked) {
      const storeClicked = _items.find((el) => el.id === store);
      rows.push(storeClicked)
    }
    
    callback(rows)
  }

  const columns = [
    {
      field: "actions",
      headerName: "",
      width: 130,
      renderCell: (params) => (
        <>
          <CustomCheckbox row={params.row} />

          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleButtonAvailability(params.row.id)}
            disabled={!params.row.checked}
          >
            {!loadingCheck && <RemoveRedEyeIcon />}
            {loadingCheck && <HourglassTopIcon />}

          </IconButton>
          {params.row.status === 2 && <ReportProblemIcon onClick={(e) => handleIssues(params.row)} sx={{ color: 'red' }} />}
          {params.row.status === 1 && <VerifiedIcon sx={{ color: 'green' }} />}
        </>
      ),
    },
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 120,
      renderCell: (params) => (
        formatBandeira(params.row.retailer)
      ),
    },
    {
      field: "id",
      headerName: "Sigla",
      width: 100
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "state",
      headerName: "UF",
      width: 120
    },
    {
      field: "region",
      headerName: "Região",
      width: 120
    }
  ];

  return (
    <React.Fragment>

      <Box sx={{ padding: 1, height: "calc(100vh - 330px)", overflowY: "scroll", overflowX: "hidden" }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
          <div style={{ width: '300px' }}>
            <FormControl fullWidth>
              <InputLabel id="lblRegiao">Região</InputLabel>
              <Select
                fullWidth
                value={regiao}
                labelId="lblRegiao"
                id="cmbRegiao"
                name="regiao"
                label="Região"
                onChange={handleChangeSelect}
              >
                <MenuItem key="all" value="Todas">Todas</MenuItem>
                <MenuItem key="SP" value="SP">São Paulo</MenuItem>

                {regionsFiltro.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.regiao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: '300px' }}>
            <FormControl fullWidth sx={{ marginBottom: "5px" }}>
              <TextField
                margin="dense"
                name="id"
                label="Busca"
                type="text"
                onChange={(e) => setFilter(e.target.value)}
              />
            </FormControl>
          </div>
          <div style={{ width: '400px' }}>
            <Typography style={{ fontSize: 12, fontWeight: "bold" }}>{items.length === 1 ? `1 loja encontrada` : `${items.length} lojas encontradas`}</Typography>
          </div>
        </div>

        <DataGrid
          rows={items}
          columns={columns}
          loading={loading}
          autoHeight
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: "Nada selecionado",
          }}
          getRowId={(row) => row.id}     
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              padding: "2px", // Espaçamento interno reduzido
              cursor: "pointer",
            }
          }}
        />
      </Box>

      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>{issuesStore} - Verifique os Ativos sinalizados</DialogTitle>
        <DialogContent>
          
          <Box sx={{ padding: 1, height: "calc(100vh - 450px)", overflowY: "scroll", overflowX: "hidden" }}>
            <DataGrid
              rows={issuesList}
              columns={columnsPopup}
              loading={loading}
              autoHeight
              hideFooter
              localeText={{
                noRowsLabel: "Nada encontrado",
              }}
              getRowId={(row) => row.id}
              sx={{
                ".MuiDataGrid-overlayWrap": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapper": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                  padding: "5px", // Espaçamento interno reduzido
                },
              }}
            />
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => {}} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
}

export default ProposalStoreGrid;
