import React, { useState, useEffect } from "react";

import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { formatDate, formatBandeiraExt } from "../../router/store/types";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

function ProposalAssetGrid({ rows, header, callback, edit }) {

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([]);

  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);

  const [assetsList, setAssetsList] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleNewClick = async () => {
    await fetchAllAssets();
    setNewDialog(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewDialog(false);
  };

  const handleGetAssets = () => {
    
    const selectedData = assetsList.filter((row) => rowSelectionModel
      .includes(row.id))
      .map((el) => ({
        ...el,
        start_date: new Date(header.start_date),
        end_date: new Date(header.end_date),
    }));

    setItems(selectedData)

    handleClose();

    callback(selectedData)
  }

  const handleSelectionChange = (newSelection) => {
    setRowSelectionModel(newSelection);
  };

  const fetchAllAssets = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset`, {
          params: {
            retailer: header.retailer
          }
        })
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setAssetsList(response.data);
          setRowSelectionModel(items.map((el) => (el.id)))
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setAssetsList([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const handleValueChange = (e, params) => {
    let newValue = Number(e.target.value);

    if (newValue % params.row.multiplier > 0) {
      newValue -= (newValue % params.row.multiplier)
      e.target.value = newValue
    }

    let value = Math.floor(e.target.value) > Math.floor(params.row.qty) ? params.row.qty : e.target.value;
    params.api.setEditCellValue({ id: params.id, field: 'qty_proposta', value: Math.floor(value) });

    let _rows = items.map((item) =>
      item.id === params.row.id ? { ...item, qty_proposta: newValue } : item
    )

    setItems(_rows);

    callback(_rows)
  };

  useEffect(() => {
    setLoading(true)
    setItems(rows)
    setLoading(false)
  }, [rows]);

  const handleRemove = (id) => {
    let _items = items.filter((el) => el.id !== id);
    setItems(_items)
    callback(_items)
  };

  const handleProcessRowUpdate = (row) => {
    let _items = items.map((el) => (el.id === row.id ? row : el))
    setItems(_items)
    callback(_items)
    return row
  };

  const columnsPopup = [
    {
      field: "title",
      headerName: "Ativo",
      flex: 1,
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 180
    },
    {
      field: "type_desc",
      headerName: "Tipo",
      width: 120
    }
  ];

  const columns = [
    {
      field: "title",
      headerName: "Ativo",
      flex: 1
    },
    {
      field: 'start_date',
      headerName: 'Início',
      type: 'date',
      width: 160,
      editable: true, 
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px' }}>✏️ {formatDate(params.value)}</div>
      ),
    },
    {
      field: 'end_date',
      headerName: 'Fim',
      type: 'date',
      width: 160,
      editable: true,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px' }}>✏️ {formatDate(params.value)}</div>
      ),
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 180
    },
    {
      field: "type_desc",
      headerName: "Tipo",
      width: 100
    },
    {
      field: "qty_proposta",
      headerName: "Qtde.",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px' }}>✏️ {(params.row.qty_proposta) ? params.row.qty_proposta : 0}</div>
      ),
      renderEditCell: (params) => (
        <TextField
          type="number"
          inputProps={{
            step: params.row.multiplier,  // Força valores inteiros
            min: 0,
            max: params.row.qty,
            pattern: "[0-9]*"  // Impede a entrada de não-números
          }}
          value={params.value}
          onKeyDown={(event) => event.preventDefault()}
          onChange={(event) => handleValueChange(event, params)}
        />
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => handleRemove(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        style={{
          marginLeft: "0px",
          display: "flex",
          justifyContent: "right",
          gap: "40px",
          width: "100%",
          marginBottom: "25px",
        }}
      >
        <>
          {items.length > 0 && (
            <>
              <div style={{ width: "100%", textAlign: "right", display: 'flex', justifyContent: 'space-between', padding: '2px' }}>

                <div style={{ fontWeight: "bold", fontSize: '12px', display: 'block', textAlign: 'center', border: '1px solid #ccc', width: '300px', padding: '10px', backgroundColor: 'lightyellow' }}>
                  <div>Clique duplo para editar as células</div>
                </div>
              </div>
            </>
          )}
        </>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "120px" }}
          startIcon={<AddIcon />}
          onClick={handleNewClick}
        >
          Ativos
        </Button>
      </div>

      <Box sx={{ padding: 1, height: "calc(100vh - 400px)", overflowY: "scroll", overflowX: "hidden" }}>
        <DataGrid
          rows={items}
          columns={columns}
          loading={loading}
          autoHeight
          disableRowSelectionOnClick
          hideFooter
          localeText={{
            noRowsLabel: "Nada selecionado",
          }}
          getRowId={(row) => row.id}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => console.error('Erro na atualização da linha:', error)} // Gera erro ao atualizar
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              padding: "15px", // Espaçamento interno reduzido
            },
          }}
        />
      </Box>

      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{formatBandeiraExt(header.retailer)} - Selecione os Ativos desejados</DialogTitle>
        <DialogContent>
          
          <Box sx={{ padding: 1, height: "calc(100vh - 450px)", overflowY: "scroll", overflowX: "hidden" }}>
            <DataGrid
              rows={assetsList}
              columns={columnsPopup}
              loading={loading}
              autoHeight
              checkboxSelection
              hideFooter
              localeText={{
                noRowsLabel: "Nada encontrado",
              }}
              getRowId={(row) => row.id}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              sx={{
                ".MuiDataGrid-overlayWrap": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapper": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                fontSize: "10px", // Tamanho da fonte reduzido
                "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                  fontSize: "12px", // Tamanho da fonte reduzido para cabeçalho e células
                  padding: "5px", // Espaçamento interno reduzido
                  cursor: "pointer",
                },
              }}
            />
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleGetAssets} color="primary">
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
}

export default ProposalAssetGrid;
